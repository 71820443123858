
import $ from 'jquery'
   











// $('.project-list').mixItUp({
 
//   effects: ['fade','rotateZ'],
//      easing: 'snap',
// }); 



  
	  	
    // CounterUp
	$(document).ready(function( $ ) {
		if($("span.count").length > 0){	
			$('span.count').counterUp({
					delay: 10, // the delay time in ms
			time: 1500 // the speed time in ms
			});
		}
	});
	
  	// Pretty Photo





